import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import URI from 'urijs'
// import _ from 'lodash'
import {
  useOrders,
} from 'react-omnitech-api'
import { useLink } from '../../hook'
import isBrowser from '../../helpers/is-browser'
import CheckoutPaymentFailedView from './checkout-payment-failed-view'

function CheckoutPaymentFailedController(props) {
  const {
    location,
  } = props
  // prepare hook
  const { updateOrderToDecline } = useOrders()
  const { navigate } = useLink()

  // local variable
  const [loading, setLoading] = useState(false)

  const { uuid } = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      const url = new URI(location.href)
      search = url.search(true)
    }
    return search
  }, [location])
  const declineOrder = async (orderUuid) => {
    // decline order
    setLoading(true)
    try {
      await updateOrderToDecline({
        byType: 'by_uuid',
        uuid: orderUuid,
        action: 'decline',
      })
    } catch (error) {
      // suppress errors
    } finally {
      setLoading(false)
    }
  }
  const onContinue = () => {
    navigate('/cart/', { replace: true })
  }

  useEffect(() => {
    if (uuid) {
      declineOrder(uuid)
    }
  }, [])

  const viewProps = {
    onContinue,
    loading,
  }

  return (
    <CheckoutPaymentFailedView {...viewProps} />
  )
}

export default CheckoutPaymentFailedController
