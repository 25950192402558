import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmptyScreen,
  LayoutCheckout,
  // Loading,
  SEO,
} from '../../ui'
import useStyles from './checkout-payment-failed-style'

function CheckoutPaymentFailedView({
  onContinue,
  loading,
}) {
  const { t } = useTranslation()
  const { root } = useStyles()

  const seoTitle = t('screens.checkoutPaymentFailed.seo.title')
  return (
    <LayoutCheckout
      disableFooter
      disablePaymentFooter
    >
      <SEO title={seoTitle} />
      <div className={root}>
        <EmptyScreen
          title={t('screens.checkoutPaymentFailed.title')}
          message={t('screens.checkoutPaymentFailed.message')}
          button={{
            text: t('screens.checkoutPaymentFailed.buttons.continue'),
            onClick: onContinue,
            disabled: loading,
          }}
        />
      </div>
      {/* <Loading /> */}
    </LayoutCheckout>
  )
}

export default CheckoutPaymentFailedView
